import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
  {
    lazy: () => import("./layout"),
    children: [
      {
        path: ":id",
        lazy: () => import("./standalone"),
      },
      {
        path: "/ag/:id",
        lazy: () => import("./widget"),
      },
    ],
  },
]);
