import type { Router } from "@sdr/api/router";
import { TRPCClientError, httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";

const url = `${import.meta.env.VITE_API_URL}/trpc`;

export const trpc = createTRPCReact<Router, unknown, "ExperimentalSuspense">();

export const TRPCProvider = trpc.Provider;

export const trpcClient = trpc.createClient({
  transformer: superjson,
  links: [
    loggerLink(),
    httpBatchLink({
      url,
      fetch(url, options) {
        return fetch(url, { ...options, credentials: "include" });
      },
    }),
  ],
});

export type RouterInput = inferRouterInputs<Router>;
export type RouterOutput = inferRouterOutputs<Router>;

export function isTRPCError(error: unknown): error is TRPCClientError<Router> {
  return error instanceof TRPCClientError;
}

export function isInternalServerError(error: unknown) {
  return isTRPCError(error) && (error.data?.httpStatus || 500) >= 500;
}

export function isForbiddenError(error: unknown) {
  return isTRPCError(error) && error.data?.httpStatus === 403;
}
