import "./init";
import "./global.css";
import { Suspense, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ChannelProvider } from "./channel";
import { trpcClient, TRPCProvider } from "./trpc";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { TRPCClientError } from "@trpc/client";
import { toast, Tooltip } from "@exact-magic/ui";
import {} from "@exact-magic/icons";
import { UIProvider } from "./context/ui";

function isInternalServerError(error: unknown) {
  return (
    error instanceof TRPCClientError && (error.data?.httpStatus || 500) >= 500
  );
}

function retryOnInternalServerError(failureCount: number, error: unknown) {
  // Try to recover from internal errors
  return isInternalServerError(error) ? failureCount < 5 : false;
}

function Widget() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (!(error instanceof TRPCClientError)) {
              return;
            }

            if (query.state.data !== undefined) {
              toast.error("Success!", "Event added to calendar");
            }
          },
        }),
        defaultOptions: {
          queries: {
            suspense: true,
            refetchOnWindowFocus: false,
            retry: retryOnInternalServerError,
            useErrorBoundary: isInternalServerError,
          },
          mutations: {
            retry: retryOnInternalServerError,
          },
        },
      }),
  );
  return (
    <Tooltip.Provider>
      <TRPCProvider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <UIProvider>
            <ChannelProvider>
              <Suspense>
                <RouterProvider router={router} />
              </Suspense>
            </ChannelProvider>
          </UIProvider>
        </QueryClientProvider>
      </TRPCProvider>
    </Tooltip.Provider>
  );
}

let container = null;

if (!container) {
  container = document.getElementById("root") as HTMLElement;
  ReactDOM.createRoot(container).render(<Widget />);
}
