import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";

export interface UI {
  open: boolean;
  left: boolean;
}

const UIContext = createContext<{
  ui: UI;
  setUI: Dispatch<SetStateAction<UI>>;
  toggle: () => void;
}>(undefined!);

export const useUI = () => {
  const context = useContext(UIContext);
  return context || { ui: { open: true } };
};

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [ui, setUI] = useState<UI>({ open: false, left: false });

  const toggle = () => {
    setUI((current) => ({ ...current, open: !current.open }));
  };

  return (
    <UIContext.Provider value={{ ui, setUI, toggle }}>
      {children}
    </UIContext.Provider>
  );
};
